import actions from "modules/actions/sample-storage-laboratory-actions";
import isEqual from "lodash/isEqual";
import isEqualWith from "lodash/isEqualWith";

export default {
  name: "GeneralInformation",
  components: {
    actions,
  },
  props: {
    facilities: {
      type: Object,
      require: true,
    },
    type: {
      type: String,
    },
  },
  data() {
    return {
      colNum: "6",
      origFaciId: null, 
      origNPI: null,
      typeMode: "view",
      actions: new actions(),
      dateMenu: false,
      dateMenu1: false,
      dateValue: "",
      dateValue1: "",
      datePicker: [],
      panel: [0, 1, 2, 3],
      facilityCopy: {
        id: null,
        facilityId: "",
        label: "",
        taxonomyCode: "",
        facilityType: "",
        taxId: "",
        sampleTestOrderCode: "",
        terminationDate: "",
        effectivityDate: "",
        npi: {
          id: null,
          npi: ""
        }
      },
      hasChanges: false,
      isVerifying: {
        npi: false,
      },
      isLoading: false,
      rules: {
        validateLength: async (val, type) => {
          if (val === null || val === undefined || val === "")
            return "This field is required";
          if (val.length < 9 && type === "taxid")
            return "Facility Tax/EIN must not be less than 9 characters";
          if (val.length < 10 && type === "taxonomy")
            return "Facility Taxonomy Code must not be less than 10 characters";
          return true;
        },
        validateFacilityId: async (val) => {
          const result = await this.doFacilityId(val);
          return result;
        },
        validateNpiNumber: async (val, type) => {
          if(!val && val.length == 0) return "This field is required."
          if(val == this.origNPI) return true;

          const result = await this.doValidateNpiNumber(val, type);
          return result;
        },
        date: [(val) => !val || this.isValidDate(val) || "Invalid date."],
        validTerminationDate: (val, effectivityDate) => {
          if(!val) return true;
          const terminationDate = new Date(val);
          const effectDate = new Date(effectivityDate);

          return (
            terminationDate >= effectDate ||
            "Termination date must not be less than the effective date."
          );
        },
        required: [
          (val) => {
            if (
              val === null ||
              val === undefined ||
              val.length === 0 ||
              val === ""
            ) {
              return "This field is required";
            }
            return true;
          },
        ],
      },
      progress: 0,
      progressLabel: "",
    };
  },
  methods: {
    compareGeneralObject() {
      const obj = Object.keys(this.facilityCopy);
      obj.forEach((index) => {
          if(!(this.facilityCopy[index] instanceof Object)){
            if (!isEqual(this.facilityCopy[index], this.__Facility[index])) {
              this.hasChanges = true;
              return;
            }
          }else if(index === "npi"){
            const resultOfNpi = isEqualWith(
              this.facilityCopy[index],
              this.__Facility[index],
              (value, otherValue) => {
                if (
                  value.npiNumber == undefined ||
                  otherValue.npiNumber == undefined
                )
                  return true;
                return (
                  value.npiNumber == otherValue.npiNumber
                );
              }
            );
            if (!resultOfNpi) {
              this.hasChanges = true;
              return;
            }
          }
      });
    },
    onEditMode(){
      this.typeMode = "edit";
    },
    async onSave(){
      const formValidate = await this.$refs.form.validateAsync();
      if(!formValidate){
        this.showNotifyMessage({
          message: `Please provide all necessary fields.`,
          type: "danger",
        });
        return;
      }
      this.compareGeneralObject();
      if(this.hasChanges){
        this.$emit("saveConfirmModal");
      }else{
        this.showNotifyMessage({
          message: `Please make changes in general information`,
          type: "danger",
        });
      }
    },
    async onCancel(){
      this.compareGeneralObject();
      if(this.hasChanges){
        this.$emit("cancelConfirmModal", this.facilityCopy);
      }else{
        this.typeMode = "view";
        this.$emit("closeEdit");
      }
    },
    parseDate(date) {
      if (!this.isValidDate(date)) return "";

      const [month, day, year] = date.split("/");
      return (month && day && year) ? `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}` : null;
    },
    async doFacilityId(id) {
      if (id === null || id === undefined || id === "")
        return "This field is required";

      if (id.length < 8)
        return "Facility ID must not be less than 8 characters";

      if (id == this.origFaciId) return true;

      const results = await this.actions.searchFacilityId({
        filter: {
          facilityId: id,
          enum_patternMatch: "EXACT",
          archive: true,
          logicalOperator: "AND",
        },
        limitData: ["facilityId"],
        shouldNotReturnId: true,
      });

      return results.length > 0 ? "Facility ID already exists" : true;
    },
    async doValidateNpiNumber(value, type) {
      try {
        this.isVerifying[type] = true;
    

        if (!/^\d{10}$/.test(value)) {
          this.isVerifying[type] = false;
          return "Invalid NPI number.";
        }

        const result = await this.validateNpiNumber(value);
        if (typeof result === "string") {
          this.isVerifying[type] = false;
          return result;
        } else {
          const validateNpiUniqueNess = await this.validateNpiUniqueness(value);

          if (typeof validateNpiUniqueNess === "string") {
            return validateNpiUniqueNess;
          }
        }

        this.isVerifying[type] = false;
        return true;
      } catch (error) {
        console.log(error)
        this.isVerifying[type] = false;
        return "Invalid NPI number.";
      }
    },
    async validateNpiUniqueness(npiNumber) {
      const result = await this.actions.searchNpi(npiNumber);
      const errorMsg = "NPI number already exists.";

      return result.length ? errorMsg : true;
    },
    formatDate(value, format = "MM/DD/YYYY") {
      if (!value) return "";
      return this.$options.filters.changeDateFormat(value, format);
    },
    clearDate(date) {
      this[date] = '';
    },
    closeDateModal() {
      this.__Facility.effectivityDate = this.formatDate(this.dateValue);
      this.dateMenu = false;
    },
    closeDateModal1() {
      this.__Facility.terminationDate = this.formatDate(this.dateValue1);
      this.dateMenu1 = false;
    },
    textToUpper(val) {
      this.__Facility.facilityId = val.toUpperCase();
    },
  },
  computed: {
    __Facility: {
      get() {
        return this.facilities;
      },
      set(value) {
        this.$emit("update:facilities", value);
      },
    },
    isView() {
      return this.typeMode == "view";
    },
  },
  async beforeMount() {
    this.$nextTick(async () => {
      this.colNum = this.type == "view" ? "12" : "6"
      this.typeMode = this.type;
      
      this.origFaciId = this.facilities?.facilityId;
      this.origNPI = this.facilities?.npi?.npiNumber;
    });
  },
  async mounted(){
    this.$refs.form.resetValidation();
  },
  async created(){
    this.facilityCopy = JSON.parse(JSON.stringify(this.facilities));
    
  }
};