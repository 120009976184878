/* eslint-disable no-debugger */
import SampleStorageLaboratory from "store/models/SampleStorageLaboratory";

import StoreActions from "store/actions";

const storeActions = new StoreActions(SampleStorageLaboratory);

export default class SampleStorageLaboratoryActions {
  async create(variables) {
    return await storeActions.mutation(
      {
        relativeUrl:
          "/sample-storage-laboratory/create-sample-storage-laboratory",
        queryName: "createSampleStorageLaboratory",
        queries: variables.queries || {},
        collectionQueries: variables.collectionQueries || [],
      },
      "To create sampleStorageLaboratory.",
      {
        ...variables.params,
      }
    );
  }

  async createNpi(variables) {
    return await storeActions.mutation(
      {
        relativeUrl: "/sample-storage-laboratory/create-Npi",
        queryName: "createNpi",
      },
      "To create Npi.",
      {
        ...variables.params,
      }
    );
  }

  async update(variables) {
    return await storeActions.mutation(
      {
        relativeUrl:
          "/sample-storage-laboratory/update-sample-storage-laboratory",
        queryName: "updateSampleStorageLaboratoryDetails",
        queries: variables.queries || {},
        collectionQueries: variables.collectionQueries || [],
      },
      "To update sampleStorageLaboratory.",
      {
        storageLabId: variables.storageLabId,
      }
    );
  }

  async searchFacilityId(variables, desciption = "") {
    return await storeActions.query({
      queryName: "sampleStorageLaboratories",
      relativeUrl: "/sample-storage-laboratory/search-facility-id",
      ...variables,
    }, desciption || "To search existing Facility ID.");
  }

  async get(params) {
    const { limitData, variables } = params;

    return await storeActions.query(
      {
        queryName: "getSampleStorageLaboratory",
        relativeUrl: "/sample-storage-laboratory/get-sample-storage-laboratory",
        limitData: limitData || null,
      },
      "To fetch sample laboratory.",
      { ...variables }
    );
  }

  async activate(variables) {
    const { id, limitData = [], shouldNotReturnId = false } = variables;
    return await storeActions.mutation({
      queryName: "activateSampleStorageLaboratory",
      relativeUrl: "/sample-storage-laboratory/activate",
      limitData,
      shouldNotReturnId,
    }, "To activate facility.", { id });
  }

  async deactivate(variables) {
    const { id, limitData = [], shouldNotReturnId = false } = variables;
    return await storeActions.mutation({
      queryName: "deactivateSampleStorageLaboratory",
      relativeUrl: "/sample-storage-laboratory/deactivate",
      limitData,
      shouldNotReturnId,
    }, "To deactivate facility.", { id });
  }
}
