import Actions from "modules/actions";
import NpiActions from "store/actions/npi";
import SampleStorageLaboratory from "store/actions/sampleStorageLaboratory";
import EmailActions from "store/actions/email";
import ContactInformationActions from "store/actions/contact-information";
const sampleStorageLaboratory = new SampleStorageLaboratory();
const npiActions = new NpiActions();
const emailActions = new EmailActions();
const contactInformationActions = new ContactInformationActions();

export default class SampleStorageActions extends Actions {
  async searchNpi(npiNumber) {
    return await npiActions.searchNpi(npiNumber);
  }

  async createSampleStorageLaboratory(variables) {
    return sampleStorageLaboratory.create(variables);
  }

  async createNpi(variables) {
    return sampleStorageLaboratory.createNpi(variables);
  }

  async updateSampleStorageLaboratory(variables) {
    return sampleStorageLaboratory.update(variables);
  }

  async searchFacilityId(facilityId) {
    return await sampleStorageLaboratory.searchFacilityId(facilityId);
  }

  async createEmail(params) {
    return await emailActions.create(params);
  }

  async createContactInformation(params) {
    return await contactInformationActions.create(params);
  }

  async updateContactInformation(params) {
    return await contactInformationActions.update(params);
  }

  async getSampleStorageLaboratory(params) {
    return await sampleStorageLaboratory.get(params);
  }

  async activateFacility(params) {
    return await sampleStorageLaboratory.activate(params);
  }

  async deactivateFacility(params) {
    return await sampleStorageLaboratory.deactivate(params);
  }
}
